// Required
@use 'sass:map';

@import './bootstrap-core.scss';

:root {
  --danger-dark: #d0010f;
  --secondary-dark: #f6860e;
  --primary-dark: #0044ab;
  --grey-dark: #605e72;
  --light-dark-grey: #c3c0c0;
}

// https://stackoverflow.com/questions/11173106/apply-style-only-on-ie
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  * {
    min-height: 0.01px;
  }

  body {
    background-color: red;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    width: 100%;
  }

  .social-media svg {
    color: $secondary;
  }
}

// Optional
@import '../../node_modules/bootstrap/scss/root';
//@import "~bootstrap/scss/code";
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/input-group';
@import '../../node_modules/bootstrap/scss/custom-forms';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/card';
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";

@import '../../node_modules/bootstrap/scss/badge';
//@import "~bootstrap/scss/jumbotron";
@import '../../node_modules/bootstrap/scss/alert';
//@import "~bootstrap/scss/progress";
@import '../../node_modules/bootstrap/scss/media';
//@import "~bootstrap/scss/list-group";
@import '../../node_modules/bootstrap/scss/close';
//@import "~bootstrap/scss/toasts";
@import '../../node_modules/bootstrap/scss/modal';
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/print';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/code';

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/fonts/montserrat-v15-latin-regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url(/fonts/montserrat-v15-latin-600.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-display: optional;
  font-style: normal;
  font-weight: 800;
  src: url(/fonts/montserrat-v15-latin-800.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: optional;
  src: url(/fonts/montserrat-v15-latin-900.woff2) format('woff2');
}

img {
  max-width: 100%;
}

body {
  font-family: $font-family-base;
  font-display: optional;
  font-style: normal;
  font-weight: 400 600 800 900; // Range of weights supported
  min-height: 100vh;
  padding-top: 124px;

  @include media-breakpoint-only(lg) {
    padding-top: 163px;
  }

  @include media-breakpoint-only(md) {
    padding-top: 149px;
  }

  @include media-breakpoint-only(sm) {
    padding-top: 93px;
  }

  @include media-breakpoint-only(xs) {
    padding-top: 93px;
  }
}

#__next {
  min-height: calc(100vh - 146px);
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.main-layout {
  flex: 1 1 auto;

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}

.table {
  td {
    vertical-align: middle;
  }
}

.slick-slider {
  .slick-slide {
    display: flex !important;
    flex-shrink: 0;
    flex-direction: column;
    height: auto; // ← that must not be ignored
    > div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    > div > div {
      flex-grow: 1;
      outline: none;
    }
  }

  &:not(.slick-vertical) {
    .slick-track {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
    }
  }
}

.border {
  border-width: 3px !important;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.text-shadow {
  text-shadow: 0 0 5px #2e3339;
}

.card {
  border: none;
  box-shadow: 0 0 16px rgb(0 0 0 / 10%);

  &-header {
    background: none;
    border: none;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 0;
  }
}

.text-button-link {
  text-decoration: underline;
}

.btn-squared {
  border-radius: 4px;
}

.btn-semi-squared {
  border-radius: 14px;
}

.btn-outline-primary {
  background: white;
}

.btn-shadow {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
}

.full-rounded {
  border-radius: 50%;
}

.card-rounded {
  border-radius: 1rem !important;
}

.heading {
  font-family: 'Montserrat', sans-serif;
  font-display: optional;
  padding-bottom: 0;
  margin-bottom: map.get($spacers, 7);

  &-center {
    &::after {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &::after {
    content: '';
    display: block;
    height: 6px;
    width: 30%;
    background: var(--grey-dark);
    margin-top: map.get($spacers, 4);

    @include media-breakpoint-up(lg) {
      margin-top: map.get($spacers, 5);
    }
  }

  &-small {
    &::after {
      margin-top: 1rem;
      height: 5px;
    }
  }

  &-full::after {
    width: 100%;
  }

  &-full-line::after {
    width: 100%;
  }

  &-md- {
    @include media-breakpoint-up(md) {
      &left {
        &::after {
          margin-left: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: map.get($spacers, 6);
  }

  @each $key, $val in $theme-colors {
    &-#{$key} {
      &::after {
        background: $val;
      }
    }
  }
}

h4 {
  font-weight: bold;
}

input.form-control.form-control-squared {
  border-radius: 0;
  border: 2px solid #111;
  background-color: transparent;
}

.primary-dark-color {
  color: var(--primary);
}

.middle-blue-color {
  color: var(--middle-blue);
}

button.middle-blue-color:hover,
a.middle-blue-color:hover {
  color: var(--primary);
}

button.dark-grey-color:hover,
a.dark-grey-color:hover {
  color: var(--primary);
}

.text-smaller {
  font-size: 95%;
}

.form-group-heading {
  color: var(--primary);
  font: normal normal 900 18px/19px $font-family-form;
  font-display: optional;
  margin-bottom: 24px;
}

.form-group {
  margin-bottom: $spacer * 1.5;

  a {
    text-decoration-line: underline;
  }
}

h1,
.h1 {
  font-weight: 900;
  margin-top: $spacer * 2;
  line-height: 3rem;

  @include media-breakpoint-down(md) {
    font-size: $h1-font-size * 0.75;
    line-height: 2.5rem;
  }
}

h2,
.h2 {
  font-weight: 900;
  margin-bottom: map.get($spacers, 6);

  @include media-breakpoint-down(lg) {
    font-size: $h2-font-size * 0.78;
    margin-bottom: map.get($spacers, 5);
  }
}

h3,
.h3 {
  @include media-breakpoint-down(lg) {
    font-size: $h3-font-size * 0.88;
  }
}

h3,
h4,
h5 {
  font-weight: 800;
}

h1,
h2,
h3,
h4,
h5,
p {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

select.form-control {
  background: #f4f4f4 url('../assets/imgs/downArrow.svg') no-repeat;
  background-position-x: 94%;
  background-position-y: 19px;
  -webkit-appearance: none;
}

label {
  color: var(--primary);
  font-size: 1rem;
  font-weight: 600;
  font-family: $font-family-form;
  font-display: optional;
}

.form-label {
  color: $primary;
  font-size: 1rem;
  font-weight: 700;
  font-family: $font-family-form;
  font-display: optional;
}

.form-control {
  color: $black;
  /* stylelint-disable */
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--light-dark-grey);
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--light-dark-grey);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--light-dark-grey);
  }
}

.row-no-padding {
  @include media-breakpoint-down(md) {
    [class*='col-'] {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
  }
}

.horizontal-right-image-campaign,
.horizontal-left-image-campaign {
  background-color: var(--light-blue);
}

.horizontal-left-image-campaign,
.horizontal-left-image-campaign h3 {
  font-size: 1.5rem;
  font-weight: normal;
}

.horizontal-right-image-campaign h2,
.horizontal-left-image-campaign h2 {
  font-weight: 600;
  letter-spacing: 0.05rem;
}

.quote-text-container {
  padding: $spacer * 3;
  position: relative;
}

.campaign-homepage-heading {
  color: $black;
}

.full-width-margin-top {
  margin-top: 6rem;
}

.bg-white-70 {
  background: rgba(255, 255, 255, 0.7);
}

.z-0 {
  z-index: 0;
}

.z-100 {
  z-index: 100;
}

.z-1000 {
  z-index: 1000;
}

.full-width-title-container {
  h1 {
    font-size: 2rem;
    font-weight: 900;
  }

  @include media-breakpoint-down(md) {
    h1 {
      font-size: 1.7rem;
      font-weight: 700;
    }
  }
}

.max-w-750 {
  max-width: 750px;
}

.max-w-450 {
  max-width: 450px;
}

.shadow-lg {
  box-shadow: 0 0 30px 20px rgba(0, 0, 0, 0.2);
}

.no-shadow {
  box-shadow: none !important;
}

.no-pointer-events {
  pointer-events: none;
  touch-action: none;
}

.circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;

  &-md {
    width: 100px;
    height: 100px;
  }

  &-lg {
    width: 170px;
    height: 170px;
  }

  &-xl {
    width: 360px;
    height: 360px;
  }

  &-xxl {
    width: 468px;
    height: 468px;
  }
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.form-check-input {
  height: 20px;
  width: 20px;
}

.text-medium {
  font-weight: 600;
}

.text-normal {
  font-weight: normal !important;
}

.font-secondary {
  font-family: Arial, sans-serif !important;
}

.border-width-sm {
  border-width: 1px !important;
}

.border-width-md {
  border-width: 3px !important;
}

.border-width-lg {
  border-width: 4px !important;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner,
#nprogress .spinner-icon {
  display: none;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.background-cover {
  background-size: cover;
}

.background-center {
  background-position: center;
}

.object-fit-cover {
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
.object-fit-contain {
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

.mx-n15px {
  margin: 0 15px !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .border-radius#{$infix} {
      border-radius: $card-border-radius !important;
    }
    .border-radius#{$infix}-none {
      border-radius: 0 !important;
    }
  }
}

.list-style-type-none {
  list-style-type: none;
}

.min-w-auto {
  min-width: auto;
}

.max-w-100 {
  max-width: 100%;
}
.max-w-initial {
  max-width: initial;
}

.white-space-nowrap {
  white-space: nowrap;
}

.max-vh-50 {
  max-height: 50vh;
}

.vh-40 {
  height: 40vh;
}
.vh-50 {
  height: 50vh;
}

.shadow {
  box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
}

.light-shadow {
  box-shadow: 0 3px 20px $lightShadowGrey;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-inherit {
  color: inherit;
}

.modal-backdrop.show {
  opacity: 0.75;
}

.rounded-sm {
  border-radius: 8px !important;
}

.rounded-md {
  border-radius: 1.25rem !important;
}

.rounded-lg {
  border-radius: 1.7rem !important;
}

.rounded-l {
  border-top-left-radius: 1.25rem !important;
  border-bottom-left-radius: 1.25rem !important;
}

.rounded-t {
  border-top-left-radius: 1.25rem !important;
  border-top-right-radius: 1.25rem !important;
}

.rounded-l-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-r-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-none {
  border-radius: 0 !important;
}

.rounded-sm-lg {
  @include media-breakpoint-up(lg) {
    border-radius: 8px !important;
  }
}

.rounded-md-lg {
  @include media-breakpoint-up(lg) {
    border-radius: 1.25rem !important;
  }
}

.rounded-sm-md {
  @include media-breakpoint-up(sm) {
    border-radius: 1.25rem !important;
  }
}

.rounded-lg-none {
  @include media-breakpoint-down(lg) {
    border-radius: 0 !important;
  }
}

.rounded-md-none {
  @include media-breakpoint-down(md) {
    border-radius: 0 !important;
  }
}

.rounded-xs-none {
  @include media-breakpoint-down(xs) {
    border-radius: 0 !important;
  }
}

//Badge
.badge {
  @each $key, $val in $theme-colors {
    &-outline-#{$key} {
      border: solid 3px $val;
    }
  }
}

.accordion.accordion-with-border-radius {
  .card:not(:first-of-type) {
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
  }

  .card:not(:last-of-type) {
    border-bottom: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    border-bottom-left-radius: $card-border-radius;
  }
}

//SLICK OVERRIDE
.slick-next {
  right: 20px !important;
}

.slick-prev {
  z-index: 10;
  left: 20px !important;
}

.slick-next,
.slick-prev {
  height: auto !important;
  width: auto !important;

  &::before {
    font-size: 35px !important;
    color: var(--secondary) !important;
  }

  @include media-breakpoint-down(xs) {
    display: none !important;
  }
}

.large-body {
  font-size: 18px;
  line-height: 28px;
}

.slick-dots {
  bottom: 10px !important;

  li {
    margin: 0 !important;

    button::before {
      color: $secondary !important;
      opacity: 1 !important;
    }

    &.slick-active button::before {
      color: $danger !important;
    }
  }
}

.btn-circle {
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  width: 50px;
  height: 50px;

  &.btn-sm {
    width: 30px;
    height: 30px;
  }

  &.btn-md {
    width: 50px;
    height: 50px;
  }

  &.btn-xl {
    width: 70px;
    height: 70px;
  }
}

.transition {
  transition: all 0.2s ease;
}

.mx-sm-n-container {
  @include media-breakpoint-down(sm) {
    margin-left: $grid-gutter-width / 2 * -1 !important;
    margin-right: $grid-gutter-width / 2 * -1 !important;
  }
}

.mx-md-n-container {
  @include media-breakpoint-down(md) {
    margin-left: $grid-gutter-width / 2 * -1 !important;
    margin-right: $grid-gutter-width / 2 * -1 !important;
  }
}

.mx-lg-n-container {
  @include media-breakpoint-down(lg) {
    margin-left: $grid-gutter-width / 2 * -1 !important;
    margin-right: $grid-gutter-width / 2 * -1 !important;
  }
}

.mx-xs-n-container {
  @include media-breakpoint-down(xs) {
    margin-left: $grid-gutter-width / 2 * -1 !important;
    margin-right: $grid-gutter-width / 2 * -1 !important;
  }
}

.bg-primary a[href^='tel'] {
  text-decoration: inherit;
  color: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

.text-bold {
  font-weight: 800;
}

.data-tab-id {
  scroll-margin-top: 150px;
}
