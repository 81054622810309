@use 'sass:color';

$black: #2e2e2e;
$primary: #0030c5;
$secondary: #ffd109;
$danger: #f40000;
$lightShadowGrey: rgba(0, 0, 0, 0.29);
$theme-colors: map-merge(
  $theme-colors,
  (
    'gos-scheme': #134d56,
    'gold': #dfcfa3,
    'danger': $danger,
    'secondary': $secondary,
    'dark-secondary': #e8ab11,
    'primary': $primary,
    'dark-primary': #01023c,
    'middle-blue': #0060a9,
    'light-blue': #009ad2,
    'grey': #57565d,
    'dark-grey': #707070,
    'medium-grey': #d4e1f7,
    'light-grey': #f7f7f7,
    'key-line-grey': #ebebeb,
    'white': #fff,
    'black': #2e2e2e,
  )
);
$link-color: $primary;
$link-hover-color: darken($link-color, 15%) !default;
$btn-border-width: 3px;
$btn-font-weight: bold;
$btn-padding-y: 0.6rem;
$btn-padding-x: 1.7rem;
$spacer: 1rem;
$btn-focus-box-shadow: none;
$grid-gutter-width: $spacer * 2.5;
$card-border-radius: 1.25rem;
$modal-content-border-radius: $card-border-radius;
$btn-border-radius: 1.9rem;
$btn-border-radius-lg: 27px;
$btn-border-radius-sm: 18px;
$card-spacer-y: $spacer * 2;
$card-spacer-x: $spacer * 2;
$font-size-base: 1rem !default;
$line-height-base: 1.75;
$h4-font-size: $font-size-base * 1.3;
$h1-font-size: 2.625rem; // 1rem = 16px;
$h2-font-size: 2rem; // 1rem = 16px;
$h3-font-size: 1.5625rem; // 1rem = 16px;
$h4-font-size: 1.25rem; // 1rem = 16px;
$h5-font-size: 1rem; // 1rem = 16px;

$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 2,
  ),
  6: $spacer * 3,
  7: $spacer * 4,
  8: $spacer * 4.5,
  9: $spacer * 5,
  10: $spacer * 6,
  11: $spacer * 7,
  12: $spacer * 8,
  13: $spacer * 9,
);

$input-height: 50px;
$input-padding-x: 1.5rem;
$input-font-size: 1rem;
$input-font-weight: 400;
$input-bg: #f4f4f4;
$input-border-width: 0;
$input-border-radius: 10px;

//$input-border-radius: 0;
//$input-padding-x: $spacer * 1.2;
//$input-border-color: $black;

$body-color: $black;
$font-family-base: 'Montserrat', sans-serif;
$font-family-form: 'Montserrat', sans-serif;
$badge-border-radius: 0.6rem;
